import { useCallback, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import Search from "../../ui/material-kit/Search";
import useInput from "../../hooks/useInput";
import CategoriesContainer from "../../components/containers/CategoriesContainer";
import { useDebounce } from "../../hooks/useDebounce";
import GamesVirtualListContainer from "./GamesVirtualListContainer";
import Button from "../../ui/material-kit/Button";
import ProvidersModal from "../../components/modals/ProvidersModal";

const GamesPageContainer = () => {
  const { t } = useTranslation();
  const search = useInput("");

  const [isOpen, setOpen] = useState<boolean>(false);

  const fields = {
    search: t(`common.placeholder.search_games`),
    show_providers: t(`pages.games.show_providers`),
  };

  const [searchParams, setSearchParams] = useSearchParams();
  const [provider, setProvider] = useState<string>(
    searchParams.get("provider") || ""
  );
  const findGames = useDebounce(search.value, 1000);
  const [categoryFilter, setCategoryFilter] = useState<string>(
    searchParams.get("category") || ""
  );

  const changeCategory = useCallback(
    (v: string) => {
      setSearchParams({ provider: provider, category: v });
    },
    [categoryFilter, provider, setSearchParams]
  );

  const changeProvider = useCallback(
    (v: string) => {
      if (v === provider) {
        setSearchParams({ provider: "", category: categoryFilter });
        return;
      }
      setSearchParams({ provider: v, category: categoryFilter });
    },
    [categoryFilter, provider, setSearchParams]
  );

  useEffect(() => {
    const prov = searchParams.get("provider") || "";
    const cat = searchParams.get("category") || "";
    if (prov !== provider) setProvider(prov);
    if (cat !== categoryFilter) setCategoryFilter(cat);
  }, [searchParams]);

  const memoValue = useMemo(() => {
    return {
      game: findGames || undefined,
      provider: provider || undefined,
      category: categoryFilter || undefined,
    };
  }, [findGames, provider, categoryFilter]);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <>
      <Wrapper>
        <Block>
          <Search search={search} title={fields.search} isMobile={true} />
          <StButton color={"secondary"} onClick={handleOpen} id={`game-search`}>
            {fields.show_providers}
          </StButton>
        </Block>
        <CategoriesContainer
          onChange={changeCategory}
          category={categoryFilter}
        />
        <div style={{ margin: "0 -4px" }}>
          <GamesVirtualListContainer value={memoValue} />
        </div>
      </Wrapper>
      {isOpen && (
        <ProvidersModal
          isOpen={isOpen}
          onClose={handleClose}
          onChange={changeProvider}
          provider={provider}
        />
      )}
    </>
  );
};

export default GamesPageContainer;

const Wrapper = styled.div`
  display: grid;
  grid-gap: 18px;
`;

const Block = styled.div`
  display: flex;
  grid-gap: 10px;
`;

const StButton = styled(Button)`
  padding: 0;
  font-size: 14px;
`;
