import { Suspense, memo } from "react";
import styled from "styled-components";
import MondoSport from "../components/sport/MondoSport";
import GaminghubSport from "../components/sport/GaminghubSport";
import { getStorage } from "../hooks/useStorage";
import Loader from "../ui/Loader/Loader";
import Sp from "components/sport/SpSport";
import Digitain from "components/sport/DigitainSport";

type SportsProps = {
  isDesktop?: boolean;
  betBy?: any;
  setBetBy?: (betBy: any) => void;
  type: "prematch" | "live" | "prelive";
};

const Sport = ({ isDesktop, type }: SportsProps): JSX.Element => {
  const { MONDO, GAMINGHUB, SP, DIGITAIN }: any = getStorage("APP_SERVICES");
  const isMondoUser = MONDO?.VISIBLE;
  const isGaminghubUser = GAMINGHUB?.VISIBLE;
  const isSpUser = SP !== undefined;
  const isDigitainUser = DIGITAIN !== undefined;
  const pathname = window.location.pathname;

  return (
    <>
      {process.env.REACT_APP_INSTANCE === "maxgold"
        ? ((isDigitainUser && pathname === "/digitain/sport") ||
            (isDigitainUser && pathname === "/digitain/live-sport")) && (
            <Suspense fallback={<Loader width={300} />}>
              <Digitain />
            </Suspense>
          )
        : isDigitainUser && (
            <Suspense fallback={<Loader width={300} />}>
              <Digitain />
            </Suspense>
          )}
      {process.env.REACT_APP_INSTANCE === "maxgold"
        ? ((isSpUser && pathname === "/sport") ||
            (isSpUser && pathname === "/live-sport")) && (
            <Suspense fallback={<Loader width={300} />}>
              <Sp />
            </Suspense>
          )
        : isSpUser && (
            <Suspense fallback={<Loader width={300} />}>
              <Sp />
            </Suspense>
          )}
      {isMondoUser && (
        <Suspense fallback={<Loader width={300} />}>
          <MondoSport type={type} isDesktop={isDesktop} />
        </Suspense>
      )}
      {isGaminghubUser && (
        <Suspense fallback={<Loader width={300} />}>
          <GaminghubSport type={type} isDesktop={isDesktop} />
        </Suspense>
      )}
    </>
  );
};

/*const Sport = ({isDesktop, type, betBy, setBetBy}: SportsProps): JSX.Element => {
    const [CreateMondoSession, {isLoading, data}] = useCreateMondoSessionMutation();
    const [url, setUrl] = useState<string>('');
    const navigate = useNavigate()

    const t = {
        prematch: "p",
        live: "l"
    }

    const correctUrl = (str: string) => {
        let value = `${str.slice(0, str.length - 4)}&t=${t[type]}`
        return value
    }

    useEffect(() => {
        // CreateMondoSession({mode: isDesktop ? 'desktop' : 'mobile'})
        //     .then(pld => {
        //         if ('data' in pld) {
        //             let str = pld.data.sessionUrl
        //             setUrl(str)
        //             if (!isDesktop) {
        //                 window.open(correctUrl(str))
        //                 navigate('/')
        //             }
        //         }
        //     });

        CreateMondoSession({mode: isDesktop ? 'desktop' : 'mobile', t: type})
            .then(pld => {
                if ('data' in pld) {
                    let str = pld.data.sessionUrl;
                    setUrl(str)
                    if (!isDesktop) {
                        window.location.href = str;
                    }
                }
            });
    }, [type])

    return (
        isLoading || !url
            ? <AppLoader/>
            : <Iframe src={url} allowFullScreen/>
    );
};*/

export default memo(Sport);

const Iframe = styled.iframe`
  position: absolute;
  width: 100%;
  height: 100vh;
  border: none;
  top: 140px;
  left: 0;
`;
