import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { ReactComponent as MenuIcon } from "assets/images/menu_drawer_icon.svg";
import { useNavigate } from "react-router-dom";
import InfoContainer from "components/wrapper/header/InfoContainer";
import styled from "styled-components";
import { getStorage } from "../../../hooks/useStorage";
import IconButton from "../../../ui/material-kit/IconButton";
import ImageIcon from "../../../ui/material-kit/ImageIcon";
import { t } from "i18next";
import SignInModal from "../../modals/SignInModal";
import { useTypedSelector } from "../../../hooks/useTypedRedux";
import ProfileModal from "../../modals/ProfileModal";
import useOutsideClick from "../../../hooks/useOutsideClick";
import CustomDrawer from "../drawer";
import { userIdSelector } from "../../../redux/selectors/userSelector";
import { device } from "constants/deviceSizes";

const HeaderMobile = () => {
  const [isOpenDrawer, setOpenDrawer] = useState(false);
  const [isOpen, setOpen] = useState<boolean>(false);
  const [isOpenLogin, setOpenLogin] = useState<boolean>(false);
  console.log("🚀 ~ HeaderMobile ~ isOpenLogin:", isOpenLogin)

  const navigate = useNavigate();

  const handleChangeDrawer = useCallback(
    (e: any) => {
      e.stopPropagation();
      setOpenDrawer((prev) => !prev);
    },
    [setOpenDrawer]
  );

  const handleCloseDrawer = useCallback(() => {
    setOpenDrawer(false);
  }, [setOpenDrawer]);

  const handleChangeProfile = useCallback(() => {
    //  console.log(isOpen, 'close')
    setOpen((prev) => !prev);
  }, [setOpen, isOpen]);

  const handleCloseProfile = useCallback(() => {
    setOpen(false);
    // console.log('close')
  }, [setOpen]);

  const handleNavigateToMain = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const PATH = getStorage("APP_SEO.LOGO_PATH");
  const logo = require(`../../../${PATH}`);
  const userId = useTypedSelector(userIdSelector);

  const ref = useRef<HTMLDivElement>(null);

  useOutsideClick(ref, () => {
    if (userId) setOpen(false);
  });

  const Logo = useMemo(() => {
    return (
      <LogoIcon
        src={logo}
        alt={"desktop-logo"}
        onClick={handleNavigateToMain}
      />
    );
  }, [handleNavigateToMain, logo]);

  return (
    <>
      <CustomAppBar onClick={handleCloseDrawer}>
        <MenuButton id={`open-drawer`} onClick={handleChangeDrawer}>
          <MenuIcon />
        </MenuButton>

        {Logo}

        <ProfileWrapper>
          {userId ? (
            <div style={{ position: "relative" }} ref={ref}>
              <div onClick={handleChangeProfile}>
                <InfoContainer />
              </div>
              {isOpen && userId && (
                <ProfileModal
                  isOpen={isOpen}
                  onClose={handleCloseProfile}
                  isMobile={true}
                  isAbsolute={true}
                />
              )}
            </div>
          ) : (
            <div style={{ position: "relative" }}>
              {!isOpen && (
                <StyledButton id={`login-open-modal`} color={"primary"} onClick={() => { setOpenLogin(true) }}>
                  <>{t(`common.header.login`)}</>
                </StyledButton>
              )}
            </div>
          )}
        </ProfileWrapper>
      </CustomAppBar>
      <SignInModal
        isOpen={isOpenLogin}
        onClose={() => setOpenLogin(false)}
        isFullScreen={true}
      />
      <CustomDrawer open={isOpenDrawer} setClosed={handleCloseDrawer} />
    </>
  );
};

export default React.memo(HeaderMobile);

const CustomAppBar = styled.header`
  position: fixed;
  display: grid;
  grid-template-columns: ${() => getStorage("APP_SEO.TITLE") === "Maxgold365" ? "auto 1fr 1fr" : "repeat(3, 1fr)"};
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  flex-shrink: 0;
  top: 0;
  z-index: 10000;
  height: 44px;
  color: #fff;
  background: ${({ theme }) => theme.secondary.main};
  //box-shadow: 0px 0px 30px rgb(0 0 0 / 51%);
`;

const MenuButton = styled(IconButton)`
  padding-inline-start: 12px;
`;

const LogoIcon = styled(ImageIcon)`
  justify-self: center;
  max-width: 150px;
  
  @media (${device.tabletM}) {
    justify-self:  ${() => getStorage("APP_SEO.TITLE") === "Maxgold365" ? "start" : "center"};
  }
`;

const StyledButton = styled(IconButton)`
  box-sizing: border-box;
  padding: 8px;
  white-space: nowrap;
  margin-inline-end: 10px;
  height: 30px;
  border-radius: 5px;
  text-transform: uppercase;
`;

const ProfileWrapper = styled.div`
  justify-self: end;
  cursor: pointer;
`;
