import { YMInitializer } from "react-yandex-metrika";
import settings from "settings";

const services =
  settings.NODE_ENV === "development"
    ? settings.SETTINGS.SERVICES_DEV
    : settings.SETTINGS.SERVICES_PROD;

export const Metrics = () => {
  const yandexMetrics = services.YM;

  return (
    <>
      {yandexMetrics && (
        <YMInitializer
          accounts={[yandexMetrics.ID]}
          options={{
            clickmap: true,
            trackLinks: true,
            accurateTrackBounce: true,
            webvisor: true,
          }}
        />
      )}
    </>
  );
};
