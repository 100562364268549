import React, { Suspense, useEffect, useMemo, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import styled, { createGlobalStyle } from "styled-components";
import { useTypedSelector } from "../hooks/useTypedRedux";
import { useTranslation } from "react-i18next";

import getCorrectRoutes from "./routing";
import SecondHeaderDesktop from "components/wrapper/header/SecondHeaderDesktop";
import HeaderDesktop from "components/wrapper/header/HeaderDesktop";

import FooterDesktop from "../components/wrapper/footer/FooterDesktop";
import { ITheme } from "../interfaces/ITheme";
import MainDesktop from "../pages/Main.desktop";
import Sport from "../pages/Sport";
import StaticPage from "../pages/StaticPage";
import ScrollButton from "../components/scrollButton";
import { getStorage } from "hooks/useStorage";
import { PopupModal } from "components/modals/PopupModal";
import { useHasUnviewedPopupsMutation } from "redux/api/userApi";

const DesktopApp = () => {
  const { i18n } = useTranslation();
  const lang = i18n.language;
  const MESSAGES_VISIBLE = getStorage("ACCESS.PROFILE_MESSAGES.VISIBLE");

  const { pages, user, isOpenedPopupModal, unviewedPopups } = useTypedSelector(
    (state) => {
      return {
        pages: state.appSlice.pages,
        user: state.userSlice.user,
        unviewedPopups: state.userSlice.unviewedPopups,
        isOpenedPopupModal: state.userSlice.isOpenedPopupModal,
      };
    }
  );
  const pathname = window.location.pathname;

  const [betBy, setBetBy] = useState<any>(null);
  useEffect(() => {
    sessionStorage.setItem("lastUrl", pathname);
    let b = document.getElementById("betby");
    if (!pathname.includes("/sport") && !pathname.includes("/live-sport")) {
      b!.hidden = true;
      if (betBy) {
        betBy.clean();
      }
    } else {
      b!.hidden = false;
    }
    window.scrollTo(0, 0);
  }, [pathname]);

  const getRoutes = getCorrectRoutes(
    !!user,
    ["SPORT", "DIGITAIN_SPORT", "DIGITAIN_LIVE", "LIVE_SPORT", "STATIC_PAGES"],
    true
  );

  const header: boolean = useMemo(() => {
    return ["/game/"].some((item) => !pathname.includes(item));
  }, [pathname]);

  const footer: boolean = useMemo(() => {
    return ["/sport", "/live-sport", "/horses", "/profile", "/game/"].some(
      (item) => pathname.includes(item)
    );
  }, [pathname]);

  const spSportRoutes = (
    <>
      <Route path={`/sport`} element={<Sport type={"prelive"} />} />
      <Route path={`/live-sport`} element={<Sport type={"live"} />} />
    </>
  );

  const digitainSportRoutes = (
    <>
      <Route path={`/digitain/sport`} element={<Sport type={"prelive"} />} />
      <Route path={`/digitain/live-sport`} element={<Sport type={"live"} />} />
    </>
  );

  const betBySportRoutes = (
    <>
      <Route
        path={`/sport`}
        element={
          <Sport
            type={"prematch"}
            isDesktop
            setBetBy={setBetBy}
            betBy={betBy}
          />
        }
      />
      <Route
        path={`/live-sport`}
        element={
          <Sport type={"live"} isDesktop setBetBy={setBetBy} betBy={betBy} />
        }
      />
    </>
  );

  const [getUnviewedPopups] = useHasUnviewedPopupsMutation();

  useEffect(() => {
    if (MESSAGES_VISIBLE && user) {
      getUnviewedPopups({
        page: 1,
        size: 1000,
        isViewed: false,
        lang,
        order: "createdAt",
        order_type: -1,
      });
    }
  }, [MESSAGES_VISIBLE, getUnviewedPopups, lang, user]);

  const isPopupsWereOpenedAfterSignIn =
    localStorage.getItem("popupsWereOpened");

  return (
    <>
      <GlobalStyle direction={i18n.dir()} />
      {header && (
        <HeaderContainer>
          <HeaderDesktop />
          <SecondHeaderDesktop />
        </HeaderContainer>
      )}
      <Wrapper header={!header} id={"main-wrapper"}>
        <MainContainer footer={footer} header={header}>
          {unviewedPopups && user && !isPopupsWereOpenedAfterSignIn && (
            <Suspense fallback={<></>}>
              <PopupModal isOpen={isOpenedPopupModal} popups={unviewedPopups} />
            </Suspense>
          )}
          <Routes>
            {getRoutes}

            {process.env.REACT_APP_INSTANCE !== "maxgold"
              ? betBySportRoutes
              : spSportRoutes}
            {process.env.REACT_APP_INSTANCE === "maxgold" &&
              digitainSportRoutes}

            {pages
              .filter((item) => item.lang === i18n.language)
              .map((item, index) => (
                <React.Fragment key={`${index}-${item.id}`}>
                  <Route
                    path={`/static/${item.url}`}
                    element={<StaticPage id={item.id} />}
                  />
                </React.Fragment>
              ))}
            <Route path={`/`} element={<MainDesktop />} />
            <Route path={`/*`} element={<Navigate to={`/`} />} />
          </Routes>
          <ScrollButton isDesktop />
        </MainContainer>
        {!footer && <FooterDesktop />}
      </Wrapper>
    </>
  );
};

export default React.memo(DesktopApp);

const GlobalStyle = createGlobalStyle<{
  theme: ITheme;
  direction: "ltr" | "rtl";
}>`
  body {
    min-width: 1200px;
    background-color: ${(props) => props.theme.background.main};
    margin: auto;
    direction: ${(props) => props.direction || "ltr"};

    ::-webkit-scrollbar-thumb {
      background-color: ${(props) => props.theme.primary.light};
    }
  }
`;

const HeaderContainer = styled.header`
  width: 100%;
  min-width: 1200px;
  z-index: 1000;
  left: 0;
`;

const MainContainer = styled.div<{ footer?: boolean; header?: boolean }>`
  display: flex;
  flex-direction: column;
  max-width: 1920px;
  width: 100%;
  min-width: 1200px;
  margin: 0 auto;
`;

const Wrapper = styled.div<{ footer?: boolean; header?: boolean }>`
  box-sizing: border-box;
  height: ${({ header }) => (header ? "100vh" : "calc(100vh - 138px)")};
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
